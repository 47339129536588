.ScanQRcodeWrapper {
    padding: 20px 15px;

    .ScanQRcodeTitle {
        font-size: 30px;
        font-weight: 500;
        color: var(--ion-color-primary);
        text-align: center;
        line-height: 30px;
        margin: 40px 0;
    }

    .ScanQRcodeTitleSmall {
        font-size: 20px;
        font-weight: 500;
        color: var(--ion-color-primary);
        text-align: center;
        line-height: 20px;
    }

    .ScanQRcodeImage {
        margin:40px auto;
        max-width:250px;
        display:block;
    }
}
