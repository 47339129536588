.StoreSearchInner {
    padding: 20px 15px 5px 15px;

    .search-box {
        background-color: #F5F5F5;
        display: flex;
        border-radius: 5px; 
        border: 1px solid var(--ion-color-white);
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        .locate-icon {
            cursor: pointer;
            padding-left: 10px;
            padding-right: 10px;
        }
        .pin-icon {
            padding-left: 10px;
            padding-right: 10px;
        }
        .disable {
            opacity:0.4;
            pointer-events:none;
        }
    }

    .SearchInput {
        display: flex;
        align-items: center;
    }


    .SearchTitle {
        display: block;
        font-weight: 600;
        font-size: 22px;
        color: var(--ion-color-dark);
    }

    .SearchAutocomplete {
        //width: 100%;
        position: relative;
        display: inline-block;
        flex-grow: 1;
    }

    .SearchAutocompleteContent {
        display: block;
        position: absolute;
        background-color: #ffffff;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: 10;
    }

    .SearchAutocompleteContentItem {
        cursor: pointer;
        font-size: 14px;
    }


}

.StoreListHeader {
    margin-top: 40px;
    border-bottom: 1px solid #0000004f;
    .HeaderTitle{
        margin-left: 27px;
        font-size: 20px;
        display: inline-block;
        border-bottom: 3px solid var(--ion-color-primary);
        line-height: 18px;
    }
}

.StoreListInner {
    ion-grid {
        padding-left: 0;
        padding-right: 0;
        ion-col {
            padding-left: 0;
            padding-right: 0;
        }
    }    
    .StoreSearchTitle {
        color: var(--ion-color-primary);
        font-size: 33px;
        margin-bottom: 0px;
        margin-left:20px;
        margin-right:20px;
    }
    .StoreSearchSubTitle {
        margin-left: 20px;
        margin-right: 70px;
        margin-top: 0;
        font-size: 20px;
    }
    .LocationItemRadio {
        border: 1px solid var(--ion-color-primary);
        border-radius: 50%;
        width: 26px;
        height: 26px;
        margin: 0 20px 0 0;
        &.radio-checked {
            position: relative;
            &:after {
                content: '';
                position: absolute;
                left: 0px;
                top: 0px;
                width: 16px;
                height: 16px;
                background-color: var(--ion-color-primary);
                border-radius: 50%;
                border: 4px solid var(--ion-color-white);
            }
        }
    }
}