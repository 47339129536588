.AuxJsonFilterRadioGroup{
    ion-item.ProductSingleSelectItem{
        border-bottom: 0;
    }
}

.SingleSelect__List {
    margin-left: 1.4rem;
}

.SingleSelect__name {
    font-size: 16px;
}

.SingleSelect__price {
    font-weight: bold;
    font-size: 14px;
}

ion-item.ProductSingleSelectItem {
    //margin: 0 20px;
    display: flex;
    margin-right: 1.25rem;
    margin-left:1.25rem;
    margin-bottom: 0.75rem;
    &::part(native) {
        border: 1px solid #262626;
        border-radius: 12px;
        height: 50px;
    }
    &.item-radio-checked {
        .modifierGroupItemKcal {
            color: white;
        }
        &::part(native) {
            background-color: var(--ion-color-primary);
            border-color: var(--ion-color-primary);
            color: white;
        }
    }
    ion-radio {   
        border-radius: 10px;
        height: 20px;
        width: 20px;
        --color: var(--ion-color-primary);
        --border-width: 2px;      
        border: 1px solid var(--ion-color-primary); 
        margin-inline-end: 10px;
        &.radio-checked {
            background-color: white;
        }
        &::part(mark) {
            margin-top: -4px;
        }
    }
    ion-label {
        font-size: 16px;
        padding: 0 0 0px 5px;
        margin: 0;
    }
    &.CheckItem {
        margin: 0 20px;
        display: flex;
    }
}



/*


    ion-radio {
        margin: 0;
        width: 26px;
        height: 26px;
        //margin-bottom: 20px;
        //border-bottom: 1px solid var(--ion-color-medium);
        border: 1px solid var(--ion-color-primary);
        border-radius: 50%;
        color: var(--ion-color-light);

        &::part(native) {
            border: 1px solid red;

            .radio-icon {
                border: 1px solid red;
            }

            .radio-inner {
                border: 1px solid red;
            }
        }

        .radio-icon {
            color: var(--ion-color-white);
            border-color: red;
        }

        &::host(native) {
            .radio-icon {
                border-color: red;

                .radio-inner {
                    border-color: red;
                    border-width: 2px 2px 2px 2px;
                    border-radius: 50%;
                }
            }
        }

        &.radio-checked {
            background-color: var(--ion-color-primary);
            position: relative;

            &:after {
                content: '';
                position: absolute;
                left: 0px;
                top: 0px;
                width: 16px;
                height: 16px;
                background-color: var(--ion-color-primary);
                border-radius: 50%;
                border: 4px solid var(--ion-color-white);
            }

            &::part(native) {
                .radio-icon {
                    //border-color: red;
                    .radio-inner {
                        border-color: red;
                        border-width: 2px 2px 2px 2px;
                        border-radius: 50%;
                    }
                }

                .radio-inner {
                    border-color: red;
                    border-width: 2px 2px 2px 2px;
                    border-radius: 50%;
                }
            }
        }

    }


*/
        /*&:host(.ion-color.radio-checked) {
            .radio-inner {
                border-color:red;
                border-width: 2px 2px 2px 2px;
                border-radius: 50%;
            }
        }*/
