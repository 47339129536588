
.categoryGrid {
    cursor: pointer;
    padding: 10px 5px 0 5px;

    .catImageCol {
        background-position: center center;
        background-repeat: no-repeat;
        padding: 0 5px 10px 5px;
        background-origin: content-box;
        background-size: contain;
    }

    .catDebugLabel {
        position:absolute;
        bottom:20px;
        left:10px;
        background:#fff;
        padding:4px;
        font-size:9px;
    }
}
