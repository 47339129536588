.modifierGroup
 {
    /*margin-top:10px;
    border:1px solid #f00;*/
    padding:0;

}

.SingleSelect__List, .ProductMultiSelectItem, .chargeableListWrapper, .ProductSingleSelectItem {
    .modifierGroupItemKcal{
        margin-left: 5px;
        font-size: 12px;
        color: gray;
    }
}

.modifierItemDebug{
    position: absolute;
    top: 50%;
    right: 5%;
    transform: translate(0%, -50%);
    font-size: 12px;
    padding: 5px;
    background: #ccc;
}

.chargeableListWrapper{
    color: #262626;
    margin-bottom: 0.5rem;
    margin-left: 15px;
    margin-right: 15px;
    .chargeableSingleLine{
        font-size: 21px;
        font-weight: bold;
    }

    .chargeableListName {
        font-weight:400;
        font-size: 16px;
        display: flex;
        align-items: center;
    }
    .chargeableListPrice{

        font-size: 14px;
        line-height: 14px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    .chargeableListQuantity {
        display: flex;
        align-items: center;
        justify-content: flex-end;

    }
}

.Dropdown-Label__container{
    flex: 1;
}

.ModifierGroup-Dropdown__header{

    width: 100%;
    min-height: 4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem;

    border: grey 1px dashed;

    ion-icon{
        width: 2rem;
        height: 2rem;
    }

    &.selected{
        color: white;
        background-color: #e83a2a;
        border: none;

        .Dropdown-Selected__title{
            font-weight: 300;
        }
        .Dropdown-Selected__choice{
            font-size: 1.25rem;
        }
    }
}