
.categoryRow {
    cursor:pointer;
   margin:0 10px;
   .catImageCol {
       height:calc(100vw / 6);
       max-height:100px;
       //background-image:url(../images/assets/CafeLogo.svg);
       background-position:center center;
       background-size:60% auto;
       background-repeat:no-repeat;
   }
}
