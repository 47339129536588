body {
    font-family: 'Roboto',sans-serif;
    background-color: #555 !important;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
}



.SectionTitle {
    color: var(--ion-color-medium);
    font-size:12px;
    margin:20px 0 0 0;
    padding:0 10px;
}

ion-content {
    font-family: 'Roboto',sans-serif;
}

ion-row{
    &:last-child {
        border-bottom:none;
        margin-bottom:0;
        padding-bottom:0;
    }
}

h4 {
    color:var(--ion-color-primary);
    font-size:30px;
    font-weight:500;
}

#root {

    ion-app {
        max-width: 600px;
        margin: auto;
    }
}

.toastStyle {
    text-align:center;
    --background: #262626;
    --color:white;
}




/*

@font-face {
    font-family: MorrisonsAgenda;  
    src: url("../fonts/_MorrisonsAgenda-Medium_WebTT.eot");
    src: url("../fonts/_MorrisonsAgenda-Medium_WebTT.eot?#iefix") format('embedded-opentype'), url("../fonts/_MorrisonsAgenda-Medium_WebTT.woff") format("woff"), url("../fonts/_MorrisonsAgenda-Medium_WebTT.ttf") format("truetype"), url("../fonts/_MorrisonsAgenda-Medium_WebTT.svg#web") format("svg");
    font-weight: 400;
}

@font-face {
    font-family: MorrisonsAgenda;
    src: url("../fonts/_MorrisonsAgenda-Light_WebTT.eot");
    src: url("../fonts/_MorrisonsAgenda-Light_WebTT.eot?#iefix") format('embedded-opentype'), url("../fonts/_MorrisonsAgenda-Light_WebTT.woff") format("woff"), url("../fonts/_MorrisonsAgenda-Light_WebTT.ttf") format("truetype"), url("../fonts/_MorrisonsAgenda-Light_WebTT.svg#web") format("svg");
    font-weight: 300;
}

@font-face {
    font-family: MorrisonsAgenda;
    src: url("../fonts/_MorrisonsAgenda-Semi_Bold_WebTT.eot");
    src: url("../fonts/_MorrisonsAgenda-Semi_Bold_WebTT.eot?#iefix") format('embedded-opentype'), url("../fonts/_MorrisonsAgenda-Semi_Bold_WebTT.woff") format("woff"), url("../fonts/_MorrisonsAgenda-Semi_Bold_WebTT.ttf") format("truetype"), url("../fonts/_MorrisonsAgenda-Semi_Bold_WebTT.svg#web") format("svg");
    font-weight: 500;
}

@font-face {
    font-family: MorrisonsAgenda;
    src: url("../fonts/_MorrisonsAgenda-Bold_WebTT.eot");
    src: url("../fonts/_MorrisonsAgenda-Bold_WebTT.eot?#iefix") format('embedded-opentype'), url("../fonts/_MorrisonsAgenda-Bold_WebTT.woff") format("woff"), url("../fonts/_MorrisonsAgenda-Bold_WebTT.ttf") format("truetype"), url("../fonts/_MorrisonsAgenda-Bold_WebTT.svg#web") format("svg"); 
    font-weight: 700;
    font-weight:600;
}

*/


@font-face {
    font-family: 'Roboto';
    src:  local('Roboto'), url('../fonts/Roboto-Regular-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: local('Roboto'), url('../fonts/Roboto-Italic-webfont.woff') format('woff');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: local('Roboto'), url('../fonts/Roboto-Bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: local('Roboto'), url('../fonts/Roboto-BoldItalic-webfont.woff') format('woff');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: local('Roboto Condensed'), url('../fonts/Roboto-Condensed-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: local('Roboto Condensed'), url('../fonts/Roboto-CondensedItalic-webfont.woff') format('woff');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: local('Roboto Condensed'), url('../fonts/Roboto-BoldCondensed-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: local('Roboto Condensed'), url('../fonts/Roboto-BoldCondensedItalic-webfont.woff') format('woff');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto Thin';
    src: local('Roboto Thin'), url('../fonts/Roboto-Thin-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Thin';
    src: local('Roboto Thin'), url('../fonts/Roboto-ThinItalic-webfont.woff') format('woff');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto Light';
    src: local('Roboto Light'), url('../fonts/Roboto-Light-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Light';
    src: local('Roboto Light'), url('../fonts/Roboto-LightItalic-webfont.woff') format('woff');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto Medium';
    src: local('Roboto Medium'), url('../fonts/Roboto-Medium-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Medium';
    src: local('Roboto Medium'), url('../fonts/Roboto-MediumItalic-webfont.woff') format('woff');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto Black';
    src: local('Roboto Black'), url('../fonts/Roboto-Black-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Black';
    src: local('Roboto Black'), url('../fonts/Roboto-BlackItalic-webfont.woff') format('woff');
    font-weight: 400;
    font-style: italic;
}

.ion-page {
    overflow:auto !important;
}