.MopCouponsWrapper {


    .fieldErrorMessage {
        margin-top: 10px !important;
        margin-bottom: 0px !important;
        color: var(--ion-color-danger) !important;
    }

    ion-input.couponInput {
        background-color: var(--ion-color-white);
        padding: 0 20px;
        --padding-start: 10px;
        --padding-end: 10px;
        color: var(--ion-color-dark);
        font-family: var();
        border: 1px solid black;
    }

    .CouponContainer {
        display: flex !important;
        align-items: center !important;
        height: 100%;
    }

    .CouponsContainer {
        margin-bottom: 20px;
    }

    .EnterCouponsTitle {
    }

    .RemoveCoupon {
        margin-left: -3px;
        margin-right: 5px;

        &::part(native) {
            padding: 0;
        }
    }

    .CouponCode {
        font-size: 1rem;
        span {
            font-weight: bold;

        }
    }

    .SubmitButtonWrapper {
        text-align: right;

        .SubmitCoupon {
            &::part(native) {
                padding: 18px 25px 14px 25px;
            }
        }
    }

    .cardInputWrapper {
        padding: 0 15px 15px 15px;
        position: relative;
        margin: 0 0 20px 0;
        -webkit-transition: all 0.6s linear; /* Saf3.2+, Chrome */
        -moz-transition: all 0.6s linear; /* FF3.7+ */
        -o-transition: all 0.6s linear; /* Opera 10.5 */
        transition: all 0.6s linear;

        &:after {
            -webkit-transition: border-color 0.6s linear; /* Saf3.2+, Chrome */
            -moz-transition: border-color 0.6s linear; /* FF3.7+ */
            -o-transition: border-color 0.6s linear; /* Opera 10.5 */
            transition: border-color 0.6s linear;
        }

        &.isClosed {
            padding-left: 0;

            .cardInputToggle {
                padding-left: 0;
            }

            &:after {
                content: '';
                position: absolute;
                width: 100%;
                height: calc(100% - 20px);
                border: 1px solid rgba(123,36,73, 0);
                left: 0;
                top: 15px;
                z-index: 0;
            }
        }

        &.isOpen {
            &:after {
                content: '';
                position: absolute;
                width: 100%;
                height: calc(100% - 20px);
                border: 1px solid rgba(123,36,73, 1);
                left: 0;
                top: 15px;
                z-index: 0;
            }

            .cardInputToggle {
                text-decoration: none;

                .cardInputToggleClose {
                    display: inline-block;
                }
            }

            .cardInputInner {
                display: flow-root;
            }
        }

        .cardInputToggle {
            cursor: pointer;
            padding: 5px 15px;
            background-color: var(--ion-color-lightgreen);
            z-index: 1;
            position: relative;
            display: inline-block;
            text-decoration: underline;
            -webkit-transition: all 0.6s linear; /* Saf3.2+, Chrome */
            -moz-transition: all 0.6s linear; /* FF3.7+ */
            -o-transition: all 0.6s linear; /* Opera 10.5 */
            transition: all 0.6s linear;

            .cardInputToggleClose {
                margin: 0 0 0 10px;
                color: var(--ion-color-white);
                position: relative;
                width: 20px;
                height: 20px;
                vertical-align: top;
                display: none;

                &:after {
                    content: '';
                    position: absolute;
                    left: 3px;
                    top: 3px;
                    width: calc(100% - 6px);
                    height: calc(100% - 6px);
                    background-color: var(--ion-color-primary);
                    border-radius: 50%;
                    z-index: -1;
                }
            }
        }

        .cardInputInner {
            h1 {
                color: var(--ion-color-primary);
            }

            .emailInput {
                margin: 0 0 0 0;

                &.error {
                    border: 1px solid var(--ion-color-danger);
                    background-color: var(--ion-color-lightred);
                    color: var(--ion-color-danger-shade);
                }
            }

            .cardInputWarning {
                color: var(--ion-color-danger);
                margin: 10px 0 0 0;
            }
        }
    }
}
