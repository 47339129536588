.GridOuter{
    padding:0;
    display: flex;
    flex-direction: column;
    min-height: 84vh;
}

.CategoryFooterImage__row{
    margin-top: auto;
}
.menuItemRow {
    cursor: pointer;
}

.BackButton {
    margin:0 15px 20px 15px;
    display:block;
}

.BasicGrid__wrapper{
    padding: 4px;
    padding-top: 0px;
}

.CategoryPromo__container{
    padding: 5px 10px;
    img{
        width: 100%;
    }
}

.CategoryListOuter{
    height: 100%;
}

.CategoryListContent {
    --background: var(--ion-page-background);
    border-top: solid 0.5px #979797;
}

.CategoryGroupTitle {
    color: var(--ion-color-medium);
    padding: 20px 20px 0 15px;
    font-size: 14px;
    font-weight: 600;
}

.catThumb {
    height: 100%;
    object-fit: cover;
}

.categoryRow {
    padding: 5px 0;

    .catImageCol {
        .catThumb {
            border-radius: 10px;
            overflow: hidden;
        }
    }

    .catTitleCol {
        padding: 0px 0 0 10px;
        display:flex;
        align-items:center;

        .CategoryName {
            margin: 0px 0 0 0;
            font-weight: 600;
            font-size: 16px;
        }
    }
}
