.QuantityDisabled{
    background-color: var(--ion-color-dark-tint) !important;
    color:var(--ion-color-dark) !important;
}

.QuantityButtonColour__default{
    background-color: var(--ion-color-white);
    color:var(--ion-color-dark);
}

.QuantityButtonColour__grey{
    background-color: var(--ion-color-midgrey);
    color:var(--ion-color-dark);
}

.QuantityButtonColour__green{
    background-color: var(--ion-color-primary);
    color:var(--ion-color-white);
}

.QuantityButtonColour__lightgreen {
    background-color: var(--ion-color-lightgreen);
    color: var(--ion-color-dark);
}

.QuantityValueColour__white{
    color:var(--ion-color-white);
}

.QuantityValueColour__black{
    color:var(--ion-color-dark);
}

.QuantityPrice {
    font-size: 16px;
    font-weight: 600;
    padding: 0 0px;
    position:relative;

}

.QuantityButton {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    text-align: center;
    font-size: 20px;
    line-height: 29px;
    cursor:pointer;
}

.QuantityCount {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    height: 100%;
}
.QuantityButtonSubtract {
    text-align: left;
    width: 30px;
    min-width: 30px;
    max-width: 30px;
}

.QuantityButtonAdd {
    width: 30px;
    min-width: 30px;
    max-width: 30px;

    .QuantityButton {
        margin: 0 0 0 auto;
        cursor: pointer;
    }
}

ion-item{
    --ion-background-color: transparent !important;
}