ion-item.TimeSlotItem,
ion-item.TimeSlotChoice {
    border-bottom: 1px solid var(--ion-color-midgrey);
    //margin: 0 20px;
    display: flex;

    ion-label.TimeSlotChoiceLabel {
        color: var(--ion-color-dark);
        font-weight: 600;
    }

    .minutes {
        color: var(--ion-color-medium);
        font-size: .8em;
        margin-bottom: 7px;
    }

    ion-radio {
        margin: 0;
        width: 26px;
        height: 26px;
        //margin-bottom: 20px;
        //border-bottom: 1px solid var(--ion-color-medium);
        border: 1px solid var(--ion-color-primary);
        border-radius: 50%;
        color: var(--ion-color-light);

        &::part(native) {
            border: 1px solid red;

            .radio-icon {
                border: 1px solid red;
            }

            .radio-inner {
                border: 1px solid red;
            }
        }

        .radio-icon {
            color: var(--ion-color-white);
            border-color: red;
        }

        &::host(native) {
            .radio-icon {
                border-color: red;

                .radio-inner {
                    border-color: red;
                    border-width: 2px 2px 2px 2px;
                    border-radius: 50%;
                }
            }
        }

        &.radio-checked {
            background-color: var(--ion-color-primary);
            position: relative;

            &:after {
                content: '';
                position: absolute;
                left: 0px;
                top: 0px;
                width: 16px;
                height: 16px;
                background-color: var(--ion-color-primary);
                border-radius: 50%;
                border: 4px solid var(--ion-color-white);
            }

            &::part(native) {
                .radio-icon {
                    //border-color: red;
                    .radio-inner {
                        border-color: red;
                        border-width: 2px 2px 2px 2px;
                        border-radius: 50%;
                    }
                }

                .radio-inner {
                    border-color: red;
                    border-width: 2px 2px 2px 2px;
                    border-radius: 50%;
                }
            }
        }
        /*&:host(.ion-color.radio-checked) {
            .radio-inner {
                border-color:red;
                border-width: 2px 2px 2px 2px;
                border-radius: 50%;
            }
        }*/
    }




    ion-label {
        padding: 0 0 0px 0px;
        margin: 0;
        //border-bottom: 1px solid var(--ion-color-medium);
    }


    &.CheckItem {
        margin: 0 20px;
        display: flex;
    }

    &:last-child {
        border-bottom: none;
    }
}

ion-radio-group.PickTime {
    .TimeSlotItem {
        ion-radio {
            border:1px solid var(--ion-color-medium);
        }
    }
    &.PickTimeSelected{ 
        ion-item.TimeSlotItem {
            ion-radio {
                border:1px solid var(--ion-color-primary);
            }
            ion-label.TimeSlotRadioLabel {
                color: var(--ion-color-primary);
            }
        }
    }
}


ion-item.TimeSlotItem {
    margin: 0 0 0 60px;



    &::part(native) {
        padding-left: 0;
        padding-start: 0;
        overflow: visible;
        overflow-x: visible;
        overflow-y: visible;
        padding-inline-end: 0;
    }

    ion-label.TimeSlotRadioLabel {
        margin-left: 20px;
        color: var(--ion-color-medium);
        font-size: 16px;
        font-weight:600;
    }
}


.sub-label{
    font-size: 15px;
    color: var(--ion-color-medium);
}

// Repurpose as this style is used in other place. Temp like this

.time-slot-chooser-container {

    --ion-item-border-color:rgba(38, 38, 38, 0.6);
    --ion-text-color:black;
    ion-item::part(native) {
        border-style: solid;
        border-width: 1px;
        border-radius: 10px; 
        margin-bottom: 10px;
        height: 50px;
        --padding-start: 15px;
        --min-height: unset;
        font-size: 14px;
        line-height: 14px;
        font-weight: 600;
    }
    ion-radio {   
        border-radius: 10px;
        height: 20px;
        width: 20px;
        --color:#B42867;
        --border-width: 2px;      
        --color-checked: #B42867;
        border: 1px solid #B42867; 
        margin-inline-end: 10px;
    }
    ion-radio.radio-checked::part(container) { 
        border-radius: 10px;
        height: 20px;
        width: 20px;
        background: white;  
    }
    ion-radio.radio-checked::part(mark) { 
        margin-top: -4px;
    }
    .item-radio-checked {
        --ion-text-color:white;
        --ion-item-background: #B42867;
        --ion-item-border-color: #B42867;
    }
}