.menuItemWrapper {

    //margin-top:-56px;
}

.MenuItem__ModifierGroupItemKcal{
    padding: 0 20px;
    margin-bottom: 1rem;
    display: flex;    
    color: var(--ion-color-dark-tint);
    flex-wrap: wrap;

    .ModifierGroupItem__Name{
        font-weight: 500;
    }
    .ModifierGroupItem__Label{
        flex-basis: 100%;
        font-size: .8em;
    }
    .ModifierGroupItem__Kcal{
        margin-left: 7px;
        margin-top: auto;
        font-size: .8em;
        font-style: italic;
    }
}

ion-item.VerticalRadioItem{

    margin-top: .7rem;

    .LabelList{
        text-align: center;
        padding-top: 0.5rem;
        color: var(--ion-color-medium-shade);
    
    }
    .LabelPrice{
        font-weight: bold;
    }

    ion-list{
        width: 100%;
    }

    ion-radio {
        margin: 0 auto;
        width: 26px;
        height: 26px;

        border: 1px solid var(--ion-color-primary);
        border-radius: 50%;
        color: var(--ion-color-light);

        &::part(native) {
            border: 1px solid red;

            .radio-icon {
                border: 1px solid red;
            }

            .radio-inner {
                border: 1px solid red;
            }
        }

        .radio-icon {
            color: var(--ion-color-white);
            border-color: red;
        }

        &::host(native) {
            .radio-icon {
                border-color: red;

                .radio-inner {
                    border-color: red;
                    border-width: 2px 2px 2px 2px;
                    border-radius: 50%;
                }
            }
        }

        &.radio-checked {
            background-color: var(--ion-color-primary);
            position: relative;

            &:after {
                content: '';
                position: absolute;
                left: 0px;
                top: 0px;
                width: 16px;
                height: 16px;
                background-color: var(--ion-color-primary);
                border-radius: 50%;
                border: 4px solid var(--ion-color-white);
            }

            &::part(native) {
                .radio-icon {
                    //border-color: red;
                    .radio-inner {
                        border-color: red;
                        border-width: 2px 2px 2px 2px;
                        border-radius: 50%;
                    }
                }

                .radio-inner {
                    border-color: red;
                    border-width: 2px 2px 2px 2px;
                    border-radius: 50%;
                }
            }
        }
    }

    ion-item{
        text-align: center;
        --padding-end: 0px;
        --inner-padding-end: 0px;
    }    

    ion-label{
        padding: 0;
        margin: 0;
        white-space: normal !important;
    }

    .radio-checked{
        color: var(--ion-color-dark-tint);
    }
}

.MenuItemCalories{
    margin-bottom: 10px;
}

.ProductCloseButton {
    position: absolute;
    left: 20px;
    top: 20px;
    font-size: 35px;
    cursor:pointer;
}

.MenuItemTopImage {
    //max-width:280px;
    //margin:0 auto;
    height: 100%;
    object-fit: cover;
    width:100%;
    border-radius:0;
}

.MenuItemTopImageProductPromo {
    position: absolute;
    height: 50%;
    bottom: 15px;
    max-width: 180px;
    max-height: 180px;

    @media only screen and (max-width: 600px) {
        height: 50% !important;

        &:nth-of-type(2) {
            left: 3px!important;
        }

        &:nth-of-type(3) {
            right: 3px!important;
        }
    }

    &:nth-of-type(2) {
        left: 10px;
    }

    &:nth-of-type(3) {
        right: 10px;
    }
}

/*
.MenuItemNutritionWrapper{
    margin: 0.5rem 0;
    padding: 0 20px;

    > span{
        margin-right: 20px;
    }

    .AllergyInfoLink{
        text-decoration: underline;
    }
}
*/

ion-grid.ImageHeader {
    padding: 0;

    flex: 0;

    .MenuItemTopImageWrapper {
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        background-size: auto 80%;
        background-position: center center;
        background-repeat: no-repeat;
    }
}

.MenuItemTile-icon__container {
    position: absolute;
    top: 7%;
    left: 0;
    display: flex;
    flex-wrap: wrap;
    z-index: 1;
    background: #fff;
    width: auto;
    max-width: 95%;
    padding: 3px;
    border-radius: 0 10px 10px 0;
    box-shadow: 3px 0 10px #bdbdbd;

    .MenuItemIcon {
        width: 30px;
        margin-right: 3px;
        margin-bottom: 1px;
        padding: 3px;
        margin-top: 1px;
    }
}




.ProductInfoWrapper {
    ion-title {
        font-family: 'Roboto Medium';
        &.ProductTitle {
            font-weight: 500;  
            .product-title-container {
                display: flex;
                justify-content: space-between;
                align-items: baseline;
            }
            margin: 20px 0 20px 0;
            font-size: 24px;
            position: relative;
            text-align: left;
            padding: 0 20px;
            .base-price {
                font-size: 34px;
                color: #B42867;
  
            }
        }
    }

    .MenuItemIcon {
        width: 30px;
        display: inline-block;
        margin: 0px 15px 0px 10px;
        vertical-align: middle;
    }
    .MenuItemNutritionWrapper {
        padding: 10px 20px;
        .MenuItemCalories {
            margin-right: 30px;
            font-size: 12px;
            font-weight: 400;
        }
        .AllergyInfoLink {
            font-size: 16px;
            text-decoration: none;
            cursor: pointer;
            color: #B42867;   
        }
    }

    .ProductDesciption {
        padding: 0 20px 0px 20px;
        font-size: 16px;
        line-height: 20px;
        color: var(--ion-color-dark);
    }

    .product-tags {
        margin: 10px;
        min-height: 30px;
    }

}

.ProductOptionBanner {
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 10px;
    .ProductOptionBannerTitle {
        font-family: "Roboto Medium";
        font-size:24px;
        line-height: 28px;
        font-weight: 500;
    }
    .ProductOptionBannerDescription {
        font-size:14px;
        color:var(--ion-color-medium);
    }
}