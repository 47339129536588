.InfoPage {
    padding:20px 15px;

    p{
        color: var(--ion-color-medium);
        font-size:16px;
        line-height:24px;
    }
}

