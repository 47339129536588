.NameOrNickname {
	--background: var(--ion-page-background);
	padding: 20px 15px;
}

.NameOrNickname__select-container {
	--background: var(--ion-color-white);
	margin: 1rem 0;
	--inner-border-width: 0;

	ion-select {
		font-weight: 500;
		font-size: 20px;
		margin: 0 auto;
		--placeholder-opacity: 1;
	}

	.vion-select {
		font-weight: 500;
		font-size: 20px;
		color: var(--ion-color-primary);
		margin: 0 auto;
		--placeholder-opacity: 1;
	}
}

.NameOrNickname__grid-wrapper {
	padding: 0;
	display: flex;
	flex-direction: column;
	height: 100%;
}

.NameOrNickname__content-container {
	padding: 20px 0px;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}

.NameOrNickname__form-container {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	margin: 10px;

	ion-label {
		color: #505050;
		font-weight: bold;
	}

	.fieldWrapper {
		margin: 10px 0;
	}

	.fieldErrorMessage {
		margin: 10px 0 0!important;
	}

	.NameOrNickname__inputWrapper {
		background-color: var(--ion-color-white);
		border-top: solid 0.5px var(--ion-color-medium);
		border-bottom: solid 0.5px var(--ion-color-medium);
		padding-top: 4rem;
		padding-bottom: 4rem;

		.NameOrNickname__inputTitle {
			text-align: center;
		}
	}
}

.NameOrNickname__text {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 0 10px;
}

.NameOrNickname__title {
	font-size: 1.5rem;
	margin-top: 0;
	padding: 0 10px;
}

.NameOrNickname__confirm-button {
	//background: var(--ion-color-base);
	&::part(native) {
		width: 100px;
	}
}

.NameOrNickname__input {
	background-color: var(--ion-color-white);
	padding: 0 20px;
	--padding-start: 10px;
	--padding-end: 10px;
	color: var(--ion-color-dark);
	font-family: var(--ion-font-family);
	border: solid 0.5px var(--ion-color-medium);
	border-radius: 5px;

	input {
		padding: 1rem 0.75rem;
	}
}
