.upsell-alert{
    .alert-head, .alert-message {
        text-align: center;
    }
}


.MopFooterWrapper {

    height: 6.25rem;
    background-color: var(--ion-color-dark);
    z-index: 1;
    padding: 10px;
    .MopFooterInner {
        height: 100%;
    }
    ion-row {
       height: 100%;
    }

    ion-button::part(native) 
    {
        margin: 0px 0px 0px auto;
        padding: 0px;
       max-width: 210px; 
        height: 50px;
        font-weight: 600 !important;
        font-size: 16px;
        line-height: 16px;
    }

    .CollectFrom {
        font-size: 16px;
        color: var(--ion-color-medium);
        margin-bottom: 5px;  
        font-weight: 500;
        line-height: 16px;      
    }
    .Location {
        font-size: 12px;
        line-height: 12px;          
        color: var(--ion-color-light);
    }
    .BasketTotal {
        background-color: var(--ion-color-secondary);
        padding: 20px;
        border-radius: 30px;
        color: var(--ion-color-dark);
        font-weight: 600;
    }
    .nubbin {
        border-color: var(--ion-color-light);
        border-width: 2px;
        border-style: solid;
        border-radius: 12px;
        position: absolute;
        right: 0px;
        top: 0px;
        background-color:var(--ion-color-dark);
        color:white;
        width: 25px;
        height: 25px;
        text-align: center;
        font-size: 16px;
        line-height: 16px;
        font-weight: 700;
        padding: 2px 0px 0px 0px;
    }

    ion-button.view-order[disabled] {
      opacity: 1;
    }

    .button-group-disabled {
        opacity: 0.5;
    }

}

.footerButtonWrapper {
  //  position: relative;
  //  top: 50%;
  //  transform: translateY(-50%);
/*

    display: flex;
    align-items: center;

    ion-button {
        margin: 0 0 0 auto;
    }

    .FooterBasketCount {
        padding:3px 5px 0 5px;
        position: relative;
        margin: 0 0 0 10px;
        font-weight: 500;

        &:after {
            content: '';
            background-color: rgba(255,255,255,0.6);
            border-radius: 50%;
            width: 24px;
            height: 24px;
            position: absolute;
            left: 50%;
            top: calc(50% - 1px);
            -webkit-transform: translate(-50%, -50%);
            -moz-transform: translate(-50%, -50%);
            -o-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            z-index: -1;
        }

        @media screen and (max-width: 350px) {
            
        }
    }

    */
}

.confirmButton {
 //   height:unset;
}

.MessageModal {
    .modal-wrapper {
        .ion-page {
            ion-header {
                ion-toolbar {
                    padding: 15px 10px;
                    ion-title {
                        padding: 0;
                    }
                    ion-icon.CloseButton {
                        display: inline-block;
                        font-size: 30px;
                        cursor:pointer;
                    }
                }
            }
        }
    }
    .ModalContent {
    }
}



ion-modal#upsell-modal {
    --min-height: 100%;
    --height: 100%;
    --width: 100%;
    --border-radius: 8px;
    --box-shadow: 0px 0px 15px 500px rgba(0,0,0,0.61)
}

ion-modal#upsell-modal::part(backdrop) {
    background: rgba(209, 213, 219);
    opacity: 0.2;
    visibility: visible;
}

ion-modal#upsell-modal ion-toolbar {
    --background: rgb(87 19 58);
    --color: white;
}

.dismissUpsell {
    font-size: 24px;
}
