.AllergenPopup {
  color: #252525;
  font-size: 18px;

  h1 {
    font-size: 22px;
    font-weight: bold;
    margin-top: 10px;
  }

  .link {
    color: var(--ion-color-primary);
    text-decoration: underline;
    cursor: pointer;
  }

  .modal-wrapper {
    margin-top: auto;
    align-self: self-end;
    height: 320px;
    max-height: 50%;
    border-radius: 16px 16px 0 0;
  }

  .content {
    margin: auto;
    width: 90%;
  }

  .button-row {
    display: flex;
    justify-content: space-around;

    ion-button {
      --border-radius: 9999px;
      width: 140px;
      max-width: 40%;
      text-transform: unset;
      font-family: 'Roboto';
      font-weight: 700;
    }

    .button-large {
      height: 50px;
    }

    .ion-color-light {
      --border-color: black;
      --border-style: solid;
      --border-width: 1px;
    }
  }
}
