.info {
    //background: var(--ion-color-primary);
    background-image: url(/images/assets/home_bg_image.png);
    background-position: center top;
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    height: 100%;
    transform: translateY(-50%);
    color: #FFF;

    .infoInner {
        width: calc(100% - 40px);
        margin: 0 auto;
        height: 100%;

        .InfoLogoWrapper {
            height: calc(100% - 220px);
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            max-width: 250px;
            margin: 0 auto;

            .splashLogo {
                display: block;
                width: 100%;
                margin: 0 auto;
            }
        }

        .morrisonsLogo {
            display: block;
            height: 80px;
            width: 100%;
            margin: 40px auto 0 auto;
            background-image: url(/images/assets/MorrisonsLogo.svg);
            background-position: center center;
            background-size: auto 60%;
            background-repeat: no-repeat;
        }
    }
}


// This appears to be a applying globally, move to somewhere nicer, why though?

ion-button {
    text-transform: unset;
    //max-width: 250px;
    margin: 0 auto;
    border-radius: 30px;
    font-family: var(--ion-font-family);
    //font-size: 14px;
    height:unset;

    &::part(native) {
        border-radius: 30px;
        //font-weight: 600;
        padding: 19px;
        //font-size: 14px;
    }
}

