.sandbags-container {
    background-color: var(--ion-color-dark);
    border-bottom: 1px solid #AEAEAE;
    .sandbags {
        font-size: 16px;
        line-height: 16px;
        margin-left: 5px;
        margin-right: 5px;
        margin-top: 15px;
        margin-bottom: 15px;
        color: white;
        height: 100%;
        .disable-item {
            opacity: 0.5;
            pointer-events: none;
        }
        .order-total {
            font-size: 18px;
        }
        .other-total {
            color: #AEAEAE;
        }
        .coupon-row {
            font-size: 16px;
            line-height: 16px;
            .remove {
                margin-top: 3px;
                text-align: right;
                text-decoration: underline;
            }
            .description {
                display: flex;
                align-items: center;
                .code {
                    margin-top: -2px;
                    margin-left: 5px;
                    font-family: 'Roboto Medium';
                }
            }
        }
        .coupons-add-container {

            .add-coupon {
                text-decoration: underline; 
                margin-bottom: 10px;
                width: fit-content;
                display: flex;
                align-items: center;
            }
            .coupon-widget {
                .enter-number-caption {
                    margin-bottom: 10px;
                }
                .error-bubble {
                    padding-left: 10px;
                    padding-right: 10px;
                    font-size: 12px;
                    align-items: center;
                    display: flex;
                    height: 35px;
                    border-radius: 5px;
                    background-color: #FBEBEB;
                    color: #262626;
                    .message {
                        white-space: nowrap;
                        overflow: hidden;
                        margin-left: 5px;
                        margin-top: -3px;
                        font-family: 'Roboto Medium';
                    }
                }
                .error {
                    background-color: rgba(255,255,255,0.1) !important;
                    border: 1px solid #D8393A !important;
                    ion-input {
                        color: white !important;
                    }
                    ion-button {
                        &::part(native) {
                            color: #262626 !important;
                            background-color: white !important;
                        }
                    }
                }
                .coupon-input-container {
                    display: flex;
                    border: 1px solid white;
                    background-color: white;
                    border-radius: 8px;
                    height: 55px;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 5px;
                    .coupon-input {
                        margin: 10px;
                        ion-input {
                            color: #262626;
                            font-size: 18px;
                        }
                    }
                    .coupon-apply {
                        margin: 10px;
                        ion-button {
                            &::part(native) {
                                color: white;
                                width: 70px;
                                height: 32px;
                                font-size: 12px;
                                line-height: 12px;
                                background-color: #262626;
                            }
                        }
                    }

                }
            }
        }
    }
}