.ProductMultiSelectItem {
    margin-right: 1.25rem;
    margin-left:1.25rem;
    margin-bottom: 0.75rem;
    ion-item {
        border: 1px solid #262626;
        border-radius: 12px;
        &::part(native) {
            height: 50px;
        }
        &.item-checkbox-checked {
            color: white;
            .modifierGroupItemKcal {
                color: white;
            }
            border-color: var(--ion-color-primary);
            background-color: var(--ion-color-primary);
            &::part(native) {}
        }
    }
    ion-checkbox {
        --border-color:var(--ion-color-primary);
        width: 20px;
        height: 20px;
        margin-inline: 0px 10px;
        --background-checked:white;
        --checkmark-color:var(--ion-color-primary);
        --checkmark-width:3px;
    }
    ion-label {
        font-size: 16px;
        padding: 0 0 0px 5px;
        margin: 0;
    }
}

     /*
ion-item {
    &.ProductMultiSelectItem {


   

        margin: 0 20px;
        border-bottom: 1px solid var(--ion-color-midgrey);

        ion-checkbox {
            &::part(native) {
                margin: 0;

                input[type="checkbox"] {
                    border-radius: 50%;
                }
            }
        }
        &:last-child {
            border-bottom:none;
        }

    


    }
}
    */
