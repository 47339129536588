.ChooseOrderType__container{
    padding: 30px 20px;
    // This style is used elseware, move to common styling
    .OrderType_storename {
        font-size: 24px;
        font-weight: 400;
        line-height: 24px;
    }
    // This style is used elseware, move to common styling
    .OrderType_caption {
        font-size: 16px;
        font-weight: 500;
        line-height: 16px;
        margin-bottom: 20px;
    }

    .change-store {

        font-size: 16px;
        font-weight: 400;
        color:var(--ion-color-white-contrast);

        cursor: pointer;
        text-decoration: underline;
    }

}

.OrderType_title {
    margin-bottom: 10px;
    font-size: 1.25rem;
    font-weight: bold;
}

.OrderType_button {
    max-width: unset;
    width: 100%;
    margin-bottom: 10px;
    text-align: left;
    height: 70px; 
    &::part(native){
        padding: 8px 20px;
        border-radius: 12px;
        border-color: #7d7d7d;
    }
    .button-content__container {
        width: 100%;
        font-size: 14px;
        line-height: 16px;
        font-weight: 500;
        .button_title {
            color: #262626;
            margin-bottom: 5px;

        }
        .button_sub-title {
            color: #515151;
        }
    }
    .button-content__icon {
    }
}

.eatIn-warning {
    font-size: 1.25rem;
    margin-top: 1.5rem;
    font-weight: 500;
}

.order-type-container {
    font-size: 14px;
    --ion-item-border-color:rgba(38, 38, 38, 0.6);
    ion-item::part(native) {
        border-style: solid;
        border-width: 1px;
        border-radius: 10px; 
        margin-bottom: 15px;
        height: 70px; 
        --padding-start: 15px;
        --min-height: unset;
    }
    ion-radio {   
        border-radius: 10px;
        height: 20px;
        width: 20px;
        --color:#B42867;
        --border-width: 2px;      
        --color-checked: #B42867;
        border: 1px solid #B42867; 
        margin-inline-end: 10px;
    }
    ion-radio.radio-checked::part(container) { 
        border-radius: 10px;
        height: 20px;
        width: 20px;
        background: white;  
    }
    ion-radio.radio-checked::part(mark) { 
        margin-top: -4px;
    }
    .item-radio-checked {
        --ion-text-color:white;
        --ion-item-background: #B42867;
        --ion-item-border-color: #B42867;
        .button-content__container {
            .button_title {
                color: white;
            }
            .button_sub-title {
                color: white;
            }
        }
    }
    .button-content__container {
        width: 100%;
        font-size: 14px;
        line-height: 16px;
        font-weight: 500;
        .button_title {
            color: #262626;
            margin-bottom: 5px;

        }
        .button_sub-title {
            color: #515151;
        }
    }
}