.BasketItemRowOuter {



    .basketItemRow {
        padding: 5px 0;
        margin: 5px 10px;
        position: relative;
        color: #262626;

        .BasketItemImageWrapper {
            padding-left: 0;
            padding-top: 10px;
        }

        .basket-item-description-row {
            margin-bottom: 5px;
            font-size: 14px;
            margin: 0 0 5px 0;
            font-weight: 400;
        }

        .basket-item-quantity-col {
            // ion-align-self-end not working...
            display: flex;
            justify-content: flex-end;
        }

        ion-text {

            &.BasketItemTitle {
                font-size: 16px;
                font-family: 'Roboto Medium';
            }

            &.BasketItemPrice {
                font-size: 16px;
                margin: 0 0 5px 0;
                font-weight: 600;
            }

            &.CatDescription {
                font-size: 12px;
                color: var(--ion-color-medium);
                margin: 0 0 5px 0;
            }
        }

        .basketItemEditButton {
            font-weight: 600;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #262626;
            border-radius: 16px;
            height: 32px;
            width: 85px;
            font-size: 12px;
            line-height: 12px;
            background: #fff;
        }

        .BasketItemImage {
            border-radius: 10px;
            overflow: hidden;
        }

        .BasketItemDescription {
            font-size: 14px;
            margin: 0;
            font-weight: 400;

            li {
                display: flex;
                align-items: center;

                &::before {
                    content: "";
                    width: 3px;
                    height: 3px;
                    border-radius: 50%;
                    background: black;
                    margin-right: 0.5rem;
                }
            }
        }
        /*&:last-child {
            border-bottom: none;
        }*/

        .BasketItemButtonsWrapper {
            padding: 0;

            .ProductOptionButton {
                border-radius: 50%;
                background-color: var(--ion-color-midgrey);
                width: 30px;
                height: 30px;
                text-align: center;
                font-size: 30px;
                line-height: 30px;
                margin: 0 auto;
            }

            .ProductOptionCount {
                text-align: center;
                font-size: 16px;
                font-weight: 600;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;

                .ProductOptionNumber {
                }
            }

            .QuantityInputWrapper {
                border-color: red;

                .item-inner {
                    padding-right: 0;
                }

                .item-native {
                    .item-inner {
                        padding-right: 0;
                    }
                }

                &::part(native) {
                    padding-right: 0;
                    padding-end: 0;
                    overflow: visible;
                    overflow-x: visible;
                    overflow-y: visible;
                    padding-inline-end: 0;
                }
            }
        }
    }
}

div.item-inner {
    padding-right: 0;
}



.ProductOptions {
    padding: 0 0 0px 0;

    .ProductOption {
        border-bottom: 1px solid var(--ion-color-midgrey);
        margin: 0 20px;
        padding: 5px 0;

        .ProductOptionTitle {
            font-size: 16px;
            color: var(--ion-color-medium);
            padding: 0 20px 5px 0;
            height: unset;
            font-weight: 100;
            text-align: left;
            position: relative;
        }

        .ProductOptionPrice {
            font-size: 16px;
            font-weight: 600;
            padding: 0 0px;
            position: relative;
        }

        .ProductOptionButton {
            border-radius: 50%;
            background-color: var(--ion-color-midgrey);
            width: 30px;
            height: 30px;
            text-align: center;
            font-size: 30px;
            line-height: 30px;
        }

        .ProductOptionCount {
            text-align: center;
            font-size: 16px;
            font-weight: 600;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;

            .ProductOptionNumber {
            }
        }

        &:last-child {
            border-bottom: none;
        }
    }
}

.MopBasketNotificationWrapper {
    .MopBasketNotification {
        font-size: 1.25rem;
        padding: 1rem;
        font-weight: 500;
        text-align: center;
        border-bottom: 1px solid var(--ion-color-midgrey);
    }
}