.QRlandingWrapper {
    padding: 20px 15px;
    height: 100%;
    background-color: var(--ion-color-lightgreen);
    display: flex;
    flex-direction: column;

    .QRlandingTextWrapper {
        height: 100%;

        .QRlandingTitle {
            font-size: 14px;
            color: var(--ion-color-dark);
            font-weight: 600;
            margin: 0 0 20px 0;
        }

        .QRlandingLarge {
            font-size: 30px;
            color: var(--ion-color-primary);
            font-weight: 500;
            margin: 0 0 20px 0;

            .QRlandingLocationTitle {
                font-weight: 600;
            }
        }
    }

    .QRlandingButtonsWrapper {
        display: flex;

        .QRlandingButton {
            font-weight: 100;
            font-size: 16px;
            &.change {
                border: 2px solid var(--ion-color-primary);
                background-color: transparent;
                color: var(--ion-color-primary);
                margin: 0 auto 0 0;
            }
            &.start {
                margin: 0 0 0 auto;
            }
            &.find {
                border: 2px solid var(--ion-color-primary);
                background-color: transparent;
                color: var(--ion-color-primary);
                margin: 0 auto 0 auto;
            }
        }
    }
}
