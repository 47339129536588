.LocationItem {
    border-bottom: 1px solid var(--ion-color-light);

    &.closed {
        opacity: 0.6;
    }

    ion-row {
        font-size: 16px;

        .LocationName {
            color: var(--ion-color-primary);
            font-weight: 600;
            margin-bottom: 5px;
        }

        .LocationAddress {
            font-size: 12px;
            color: var(--ion-color-dark);
            margin-bottom: 3px;
        }

        .closedWarning {
            color: var(--ion-color-medium);
            font-size: 12px;

            .ClosedWarningIcon {
                color: var(--ion-color-medium);

                &:part(native) {
                    .icon-inner {
                        svg {
                            path {
                                fill: var(--ion-color-medium);
                            }
                        }
                    }
                }
            }
        }

        .LocationMessage {
            font-size: 12px;
            color: var(--ion-color-medium);
            margin-bottom: 2px;
        }

        .OpeningTimes {
            font-size: 12px;
            color: var(--ion-color-medium);
            margin-bottom: 2px;
        }

        .LocationDistance {
            color: var(--ion-color-medium);
            font-size: 12px;
        }

        .MarketKitchenLogo, .CafeLogo {
            width: 40px;
        }

        .CafeLogo {
            margin-left: 10px;
        }

        &:last-child {
            border-bottom: none;
        }
    }

    .LocationItemRadio {
        //margin: 0;
    }
}




.location-item {
    --ion-item-background: white;
    filter: drop-shadow(0px 4px 4px #e0e0e0);
    border-radius: 0px;
    border-color: #e0e0e0;
    border-style: solid;
    border-width: 0px 1px 1px 1px;
    --min-height: 100px;
    --max-height: 100px;
    --inner-padding-start: 10px;
    --inner-padding-end: 10px;
    --ion-safe-area-right: 0px;
    --padding-start: 10px;
    --padding-end: 10px;
    margin-left: 20px;
    margin-right: 20px;
    font-size: 16px;
    position: relative;
    // Hacky, don't show but have to have it to trigger onIonChange
    ion-radio {
        --color: transparent;
        --color-checked: transparent;
        position: absolute;
        width: calc(100% + var(--inner-padding-start) + var(--inner-padding-end)); // need to add the padding of the parent
        height: 100%;
        margin: 0;
        left: calc(0px - var(--inner-padding-start));
    }

    .distance {
        text-align: right;
        padding-bottom: 0px;
        font-size: 12px;
    }

    .name {
        color: #B42867;
        padding-bottom: 0px;
    }

    .address {
        color: #262626;
        padding-bottom: 0px;
        font-size: 14px;
        line-height: 18px;
    }

    .opening-times {
        color: #262626;
        padding-bottom: 5px;
    }

    .closed-warning {
        display: flex;
        font-size: 14px;
        line-height: 18px;
    }

    .closed-warning-icon {
        width: 20px;
    }

    &.closed {
        opacity: 0.6;
    }

    &.first-item {
        border-radius: 5px 5px 0px 0px;
        border-width: 1px 1px 1px 1px;
    }

    &.last-item {
        border-radius: 0px 0px 5px 5px;
        border-width: 0px 1px 1px 1px;
    }

    &.selected {
        --ion-item-background: #e0e0e0;
    }
}
