.TableNumber {
	--background: var(--ion-page-background);
	padding: 20px 15px;
	border-top: solid 0.5px var(--ion-color-medium);
}


.TableNumber__select-container {
	--background: var(--ion-color-white);
	margin: 1rem 0;
	--inner-border-width: 0;

	ion-select {
		font-weight: 500;
		font-size: 20px;
		margin: 0 auto;
		--placeholder-opacity: 1;
	}

	.vion-select {
		font-weight: 500;
		font-size: 20px;
		color: var(--ion-color-primary);
		margin: 0 auto;
		--placeholder-opacity: 1;
	}
}

.TableNumber__grid-wrapper {
	padding: 0;
	display: flex;
	flex-direction: column;
	height: 100%;
}

.TableNumber__content-container {
	padding: 20px 0px;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}

.TableNumber__form-container {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	margin: 20px 0;

	.TableNumber__inputWrapper {
		background-color: var(--ion-color-white);
		border-top: solid 0.5px var(--ion-color-medium);
		border-bottom: solid 0.5px var(--ion-color-medium);
		padding-top: 4rem;
		padding-bottom: 4rem;

		.TableNumber__inputTitle {
			text-align: center;
		}

		.TableNumber__inputLabel {
			text-align: center;
			color: var(--ion-color-primary);
		}

		.fieldWrapper {
			margin: 10px 0;
		}

        .fieldErrorMessage {
            text-align: center;
        }
	}
}

.TableNumber__text {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 0 15px;
}

.TableNumber__title {
	font-size: 1.5rem;
	margin-top: 1rem;
	padding: 0 15px;
}

.TableNumber__confirm-button {
	//background: var(--ion-color-base);
	&::part(native) {
		width: 100px;
	}
}

.TableNumber__input {
	background: white;
	font-size: 2rem;
	text-align: center;
	color: var(--ion-color-primary);

	input {
		padding: 0;
	}
}
