.splash {

    background-color: #171717;
    display: flex;
    flex-direction: column;
    height: 100vh;

    .splash-row {
        width: auto;
    }

    .logo-background {
        width: 100%;
        object-fit: contain;
    }

    .splash-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        .button {
            &::part(native) {
                text-transform: none;
                width: 315px;
                height: 50px;
                font-size: 16px;
                line-height: 16px;
                font-weight: 600;
                font-family: 'Roboto',sans-serif;
                padding: 0px;
            }
        }
        .splashVersion {
            color: white;
            position:absolute;
            right: 0px;
            bottom: 0px;
            margin: 15px;
        }
        .message {
            text-align: center;
            width: 100%;
            left: 0;
            bottom: 0px;
            margin-bottom: 25%;
            position:absolute;
            font-size: 3rem;
            font-weight: bold;
            color: white;
        }
    }

    .consent-chaffinch {
        position: absolute;
        background:rgba(0, 0, 0, .6);
        height: 100%;
        width: 100%;
        .content-wrapper {
            position: absolute;
            bottom: 0px;
            width: 100%;
            background-color: white;
            .content {
                color: #262626;
                margin:20px;
                font-size: 16px;
                .title {
                    font-family: 'Roboto Medium';
                    font-size: 18px;
                    margin-bottom: 10px;
                    margin-top: 10px;
                }
                .information {
                    margin-bottom: 15px;
                }
                .terms-and-conditions {
                    margin-bottom: 15px;
                }
                .decline {
                    text-align: center;
                    margin-bottom: 15px;
                }
                .accept {
                    ion-button {
                        width: 100%;
                        &::part(native) {
                            border-radius: 5px;
                            height: 45px;
                            font-size: 16px;
                            font-weight: 600;
                        }
                    }

                }
                .link {
                    font-size: 16px;
                    text-decoration: underline;
                    cursor: pointer;
                    color: var(--ion-color-primary);
                }

            }
        }
    }
}