.fieldError {
	border: 1px solid var(--ion-color-danger) !important;
	background-color: var(--ion-color-lightred) !important;
	color: var(--ion-color-danger-shade) !important;
}

.fieldErrorMessage {
	margin-top: 10px !important;
	color: var(--ion-color-danger) !important;
}

.fieldWrapper {
	margin: 20px 0;
}

.padding-none {
	padding: 0px;
}

.SpinnerContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 80px;

	ion-spinner {
		width: 40px;
		height: 40px;
		opacity: 0.7;
		z-index: 20;
	}
}

.miDebugLabelHeader {
	position: absolute;
	top: 50px;
	left: 10px;
	background: #fff;
	padding: 4px;
	font-size: 9px;
	z-index: 100;
}

.BackArrowWrapper {
	display: flex;
	align-items: center;
	font-family: 'Roboto', sans-serif;
	cursor: pointer;
	z-index: 10;
	position: absolute;
	top: 50%;
	left: 0;
	transform: translate(0%, -50%);
	border-radius: 100%;

	.BackArrow {
		color: #B42867;
		font-size: 30px;
		margin-right: 0;
	}
}

.appHeader {
	--ion-color-light: white;
	font-family: 'Roboto', sans-serif;
	background: none;

	&:after {
		background-image: none;
	}

	ion-grid {
		padding: 5px 0;
	}

	.bryn-comment {
		color: #f00;
	}

	&.productAppHeader {
		height: 0;
	}

	.logged-in-banner {
		background: #262626;
		height: 50px;
		display: flex;
		font-size: 14px;
		color: #fff;
		padding: 0 10px;
		justify-content: space-between;
		height: 40px;

		div {
			display: flex;
			align-items: center;
			margin: 0;

			p {
				margin: -2px 0 0;
			}

			span {
				margin-right: 8px;
				display: block;
				width: 6px;
				height: 6px;
				background: #0DB14B;
				border-radius: 100%;
			}
		}

		button {
			font-size: 14px;
			background: none;
			text-decoration: underline;
			color: #fff;
			margin: -2px 0 0;
		}
	}

	.AppHeaderInner {
		padding: 0 0px;

		.CloseButton {
			position: relative;
			color: white;

			&:after {
				content: '';
				position: absolute;
				left: 0px;
				top: 0px;
				width: 46px;
				height: 46px;
				border-radius: 50%;
				background-color: black;
				z-index: -1;
			}
		}

		.MenuIcon {
			position: absolute;
			top: 50%;
			transform: translate(0, -50%);
			right: 15px;
			font-size: 30px;
			cursor: pointer;
			color: #B42867;
		}

		.AppHeaderTitle {
			text-align: center;
			display: flex;
			align-items: center;
			font-family: 'Roboto', sans-serif;
			font-weight: 700;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 100%;
			//max-width: calc(100% - 60px);
		}

		ion-grid {
			color: #FFF;
		}

		&.productPage {
			position: absolute;
			z-index: 1;
		}

		&.productAppHeader {
			--border-color: transparent; // removes annoying black line
		}

		.CloseButton {
			margin: 10px;
			float: right;
		}

		.toolbar-background {
			background: transparent;
		}

		.toolbar-container {
			background: transparent;
		}

		ion-title {
			padding-top: 0px;
			font-size: 18px;
		}
	}
}
