.LocationWrapper {
    padding: 20px 15px;
    background: var(--ion-color-lightgreen);
    height: 100%;
    display: flex;
    flex-direction: column;

    .textWrapper {
        height: 100%;
    }

    .LocationTitle {
        font-size: 30px;
        color: var(--ion-color-primary);
        margin: 20px 0;
    }

    &:host {
        background: var(--ion-color-lightgreen);
        margin: 20px 15px;
    }

    #background-content {
        background: unset;
    }

    .YourLocation {
        font-weight: 600;
        color:var(--ion-color-dark);
    }

    .buttonsWrapper {
        .TwoLineButton {
            width: 100%;
            display: block;
            max-width:unset;
            margin-bottom:15px;
            height:50px;

            .TwoLineButtonInner {
                display: flex;
                align-items: center;
                width:100%;

                ion-icon {
                    color: var(--ion-color-white);
                    margin-right: 15px;
                    font-size:25px;
                }

                .TwoLineButtonText {
                    display: flex;
                    flex-direction: column;
                    text-align:left;
                    width:100%;

                    .TwoLineButtonTitleTop {
                        font-size: 21px;
                        font-weight: 500;
                        margin-bottom:5px;
                    }

                    .TwoLineButtonTitleBottom {
                        font-size:12px;
                        font-weight:100;
                    }
                }
            }
        }
    }
}
