.CheckoutPage__wrapper {
    ion-content{
        //--ion-background-color: var(--ion-color-lightgreen);
    }

    .CheckoutPayCard__wrapper{
        max-width:600px;
        margin:4rem auto 0 auto;
    }
}

iframe.stepup__frame {
    background: var(--ion-color-white);
    border:none;
}