#MainModal {
	z-index: 2000 !important;
}

// sticking plaster
.fix-that-header {
	ion-header {
		ion-title {
			margin-left: 45px;
			margin-top: 10px;
			text-align: left !important;
			font-size: 20px;
			font-weight: 500;
		}
	}
}

.allergen-modal {
	ion-header {
		padding-top: 7px;
		padding-left: 10px;
		height: 44px;

		.CloseButton {
			font-size: 30px;
			cursor: pointer;
		}
	}

	.AllergenInfoContent {
		padding: 0px 15px 15px 15px;
	}
}

.AppHeaderBurgerMenu {

	width: 100%;
	margin-left: auto;
	z-index: 10;
	height: 100vh;

	ion-content {
		--background: rgba(var(--ion-page-background-rgb), 0.6);
		padding: 20px 15px;
	}

	ion-header {
		padding: 28px 0;

		.CloseButton {
			font-size: 30px;
			cursor: pointer;
		}
	}

	.modal-wrapper {
		position: absolute;
		top: 0;
		right: 0;
		background-color: var(--ion-color-white);
		height: 100%; // fallback
		height: 100dvh;
	}

	.AppHeaderMenuTitle {
		font-weight: 400;
		padding: 20px 15px 5px 15px;
		color: var(--ion-color-medium);
	}

	.AppHeaderMenuLink {
		font-size: 16px;
		color: var(--ion-color-dark);
		background-color: white;
		padding: 20px;
		font-weight: 300;
		display: flex;
		align-items: center;
		border-top: #ddd solid 0.5px;
		border-bottom: #ddd solid 0.5px;
		cursor: pointer;

		ion-icon {
			font-size: 25px;
			margin-right: 10px;
			opacity: 0.7;
		}

		.AppHeaderMenuLinkInner {
			width: 100%;
		}
	}

	.YourOrdersInner {
		border-top: #ddd solid 0.5px;
		border-bottom: #ddd solid 0.5px;
		background-color: white;
		padding-top: 5px;
		font-weight: 300;

		h4 {
			font-size: 18px;
			margin: 0 0 3px;
		}

		.yourOrdersLeft {
			padding: 0;
		}

		.yourOrdersItem {
			padding: 15px;
			border-bottom: #ddd solid 0.5px;

			&:last-child {
				border: none;
			}
		}

		.yourOrdersRight {
			padding: 0;
			text-align: right;
		}

		.yourOrdersItemTitle {
			font-size: 20px;
		}

		.yourOrdersItemDetail {
			color: var(--ion-color-medium);
		}

		ion-grid {
			padding: 0;
		}
	}

	.modal-header {
		background: rgba(var(--ion-page-background-rgb), 0.6);

		ion-icon {
			vertical-align: middle;
		}

		ion-title {
			display: inline-block;
			padding-left: 5px;
			vertical-align: middle;
			cursor: pointer;
			line-height: 30px;
		}

		border-bottom: solid 0.5px #ccc;
	}

	.TermsCondInner {
		padding: 20px 25px;

		ul {
			li {
				color: var(--ion-color-medium);
				vertical-align: top;
				display: inline-block;
			}
		}

		ol {
			counter-reset: item
		}

		ol>li {
			display: block;

			h4,
			h5 {
				display: inline-block;
			}
		}

		ol>li:before {
			content: counters(item, ".") " ";
			counter-increment: item;
			font-weight: bold;
			margin: 0 10px 0 0;
		}
	}
}

.login-modal {
	ion-header {
		--color: #000;
		background: #faf9f6;
	}

	ion-content {
		--background: #faf9f6;
		background: #faf9f6;

		ion-text {
			margin: 0 10px;
			display: block;
			border: none;
		}

		ion-item {
			--inner-padding-end: 0px;
			--padding-start: 0px;
			--border-width: 0;
		}

		ion-item:first-of-type {
			text-align: center;
			--border-width: 1px;
			--border-radius: 10px;
			--background: #fff;
			--border-style: transparent;
			margin: 0 10px;
			border: 1px solid #eeeeee;
			border-radius: 10px;
		}

		ion-checkbox {
			--border-radius: 100%;
			border-radius: 100%;
		}

		.item-native {
			border: 0;
		}
	}

	h2 {
		margin: 10px 0 25px;
	}

	h4 {
		color: #505050;
		font-size: 16px;
	}

	.login-modal-confirm-message {
		font-size: 15px;
		cursor: pointer;
	}

	.login-modal-error {
		color: #ff0000;
		font-size: 14px;
	}

	ion-checkbox {
		max-height: 23px;
		max-width: 23px;
		width: 100%;
		height: 100%;
		--border-radius: 1px;
		--border-style: transparent;
		border: 1px solid #b42867;
		border-radius: 1px;
		--checkmark-width: 2px;
	}

	.checked-checkbox {
		border: 2px solid #6e2b45;
		background: #6e2b45;
		padding: 3px;
	}

	ion-row {
		margin: 0 10px;
	}

	ion-footer {
		display: flex;
		justify-content: right;
		height: 6.25rem;
		background-color: var(--ion-color-dark);
		padding: 10px;

		ion-button {
			--color: #000;
			--background: #F2BF41;
			margin: 15px 10px;
			width: 60%;
			font-weight: 600;

			&::part(native) {
				border-radius: 35px;
				padding: 20px 50px;
			}
		}
	}
}
