.alt-quantity-select {
    height: 32px;
    display: flex;
    width: 100px;
    justify-content: space-between;
    .decrease {
      border-style: solid;
      border-width: 1px 0px 1px 1px;
      border-color: #262626;
      border-radius: 50% 0% 0% 50%;
      background-color: white;
      width: 33.33%;
      align-items: center;
      justify-content: center;
      display: flex;
    }
    .value {
      border-style: solid;
      border-width: 1px 1px 1px 1px;
      border-color: #262626;
      background-color: white;
      width: 33.33%; 
      align-items: center;
      justify-content: center;
      display: flex;
      font-size: 16px;
      line-height: 16px;
      font-weight: 500;
    }
    .add {
      border-style: solid;
      border-width: 1px 1px 1px 0px;
      border-color: black;
      border-radius: 0% 50% 50% 0%;
      background-color: #B42867;
      width: 33.33%;
      align-items: center;
      justify-content: center;
      display: flex;
    }

    .disable {
        background-color: red;
        pointer-events: none;
        svg {
            opacity: 0.6;
        }
    }

  }