.UpsellPage {
    --background: var(--ion-page-background);
    padding: 20px 15px;
    border-top: solid 0.5px #979797;

    p {
        color: var(--ion-color-medium);
        font-size: 16px;
        line-height: 24px;
    }
}
