.BasketPrice {
    font-size: 16px;
    font-weight: 600;
    padding: 0 0px;
    position:relative;
}

.BasketButton {
    border-radius: 50%;
    background-color: var(--ion-color-white);
    width: 30px;
    height: 30px;
    text-align: center;
    font-size: 30px;
    line-height: 30px;
    color:var(--ion-color-dark);
}

.BasketCount {
    color: var(--ion-color-white);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    height: 100%;
}
.BasketButtonSubtract {
    text-align:left;
}

.BasketButtonAdd {
    .BasketButton {
        margin: 0 0 0 auto;
    }
}

ion-button {
    //font-size: 14px;
    //color: var(--ion-color-dark);
    //margin: 0 0 0 auto;
    &::part(native) {
        ////border-radius: 30px;
        //font-weight: bold;
        ////padding: 22px 18px 18px 18px;
        //font-size: 14px;
        //color: var(--ion-color-dark);
    }
}