.TimeSlotPage {
    padding: 20px 15px;

    ion-label {
        padding: 0 0 0px 20px;
        margin: 0;
        //border-bottom: 1px solid var(--ion-color-medium);
    }

    .Timeslot_storename {
        font-size: 24px;
        font-weight: 400;
        line-height: 24px;
    }
    // This style is used elseware, move to common styling
    .Timeslot_caption {
        font-size: 16px;
        font-weight: 500;
        line-height: 16px;
    }



}

.time-slot-chooser {
    margin-left: 10px;
    margin-right: 10px;
}