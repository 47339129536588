.paymentSuccessWrapper {
	background: var(--ion-page-background);
	padding: 20px 15px;
	min-height: 100%;
	border-top: solid 0.5px var(--ion-color-medium);

	.paymentSuccessOrderInfoWrapper {
		background: repeating-linear-gradient( -45deg, #B42B66, #B42B66 100px, #EC6138 100px, #EC6138 200px);
		padding: 20px;
		margin: -20px -15px 30px -15px;
	}

	.paymentSuccessOrderInfo {
		background: rgba(var(--ion-page-background-rgb), 0.7);
		padding: 20px;
		text-align: center;
	}

	.paymentSuccessHeaderImage {
		max-width: 32px;
		margin: 0 auto 20px auto;
	}

	.paymentSuccessTitle {
		font-size: 1.5rem;
		font-weight: 500;
		margin-bottom: 30px;
	}

	.paymentSuccessSubtitle {
		font-size: 1.25rem;
		font-weight: 500;
		margin-bottom: 10px;
	}

	.rewardDetails {
		background-color: #FCCA02;
		border-radius: 3px;
		font-size: 25px;
		margin-bottom: 20px;
		display: flex;
		align-items: center; // Align items vertically in the center
		padding: 10px; // Optional: Add some padding for better spacing

		img {
			width: 20%;
			margin-right: 10px; // Optional: Add some space between the image and text
			transform: rotate(-5deg); // Rotate the image 15 degrees counterclockwise
			margin-right: 20px;
		}

		.ion-text {
			display: flex;
			align-items: center; // Align text vertically in the center
		}
	}



	.paymentSuccessCounterItemsTitle {
		font-size: 16px;
		color: var(--ion-color-dark);
		font-weight: 500;
	}

	.paymentSuccessCounterItemsList {
		list-style: none;
		padding-left: 15px;
		margin: 5px 0 10px 0;
		font-size: 1.5rem;
		color: var(--ion-color-primary);
	}

	.paymentSuccessTitleBold {
		font-size: 16px;
		color: var(--ion-color-dark);
		font-weight: 700;
		margin-bottom: 20px;
	}

	.paymentSuccessTitleSmall {
		font-size: 1rem;
		color: var(--ion-color-dark);
		font-weight: 500;
		margin: 20px 0 10px;
	}

	.paymentSuccessTitleThin {
		font-size: 16px;
		color: var(--ion-color-dark);
		font-weight: 500;
		margin-bottom: 5px;
		margin-top: 10px;
	}

	.paymentSuccessTitleLargeThin {
		font-size: 30px;
		color: var(--ion-color-dark);
		font-weight: normal;
		margin-bottom: 5px;
		margin-top: 10px;
	}


	.paymentSuccessLink {
		color: var(--ion-color-primary);
		cursor: pointer;
		font-weight: 500;
		margin-top: 20px;
		text-decoration: underline;

		&:hover, &:active {
			text-decoration: none;
		}
	}

	.viewOrderButton {
		margin: 0 20px 0 0;
	}

	.paymentSuccessOrderData {
		font-size: 2rem;
		font-weight: bold;
		margin-bottom: 10px;

		.orderNumberDigitWrapper {
			display: inline-block;
			margin: 5px 3px 5px 0;
			background-color: var(--ion-color-white);
			position: relative;
			width: 30px;
			height: 45px;
			border-radius: 2px;

			.orderNumberDigit {
				position: absolute;
				left: 50%;
				top: 50%;
				color: var(--ion-color-primary);
				-webkit-transform: translate(-50%, -50%);
				-moz-transform: translate(-50%, -50%);
				-o-transform: translate(-50%, -50%);
				transform: translate(-50%, -50%);
				line-height: 24px;
				z-index: 2;
			}

			&:after {
				content: '';
				position: absolute;
				left: 0;
				top: 50%;
				width: 100%;
				height: 2px;
				background-color: var(--ion-color-lightgreen);
				opacity: 0.6;
				z-index: 1;
			}
		}
	}
}
