.isFavourites {
    position: relative;
    border-radius: 15px;
    background-color: white;
    box-shadow: 1px 1px 3px #b0b0b0;
    padding-bottom: 5px;
    height: auto !important;
    display: flex;

    .MenuItem-Tile__image_container {
        width: 170px;
        height: 120px;
        float: left;

        .MenuItemImage {
            height: 100% !important;
        }
    }

    .MenuItemCaptionContainer {
        font-size: 16px;
        font-family: 'Roboto Medium';
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        font-weight: 500;
        padding: 0 3px;
        text-align: center;
        margin: 0 20px 0 0;
        height: auto !important;
        white-space: normal !important;

        .MenuItemCaption {
            display: flex;
            flex-direction: column;
            text-align: left;
            color: var(--ion-color-dark);

            span {
                color: #000;
                margin: 5px 0 0 0;
            }
        }
    }
}

.no-favourites {
    padding: 0 20px;
}

.isFavouritesOnMenuPage {
    position: relative;
    border-radius: 0;
    background-color: white;
    box-shadow: 1px 1px 3px #b0b0b0;
    padding-bottom: 5px;
    height: auto !important;
    display: flex;
    margin: 0 0 15px 0;

    .MenuItem-Tile__image_container {
        width: 170px;
        height: 120px;
        float: left;

        .MenuItemImage {
            height: 100% !important;
            object-fit: contain;
        }
    }

    .MenuItemCaptionContainer {
        font-size: 16px;
        font-family: 'Roboto Medium';
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        font-weight: 500;
        padding: 0 3px;
        text-align: center;
        margin: 0 20px 0 0;

        .MenuItemCaption {
            display: flex;
            flex-direction: column;
            text-align: left;
            color: var(--ion-color-dark);

            span {
                color: #000;
                margin: 5px 0 0 0;
            }
        }
    }
}
.MenuItem-Tile_Category__container {
    height: 112px;
    position: relative;
    border-radius: 15px;
    background-color: white;
    box-shadow: 2px 2px 7px #b0b0b0;

    .MenuItem-Tile__image_container {
        overflow: hidden;
        height: 70%;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;

        .MenuItemImage {
            width: 100%;
            object-fit: cover;
            height: 112px;
        }
    }

    .MenuItemCaptionContainer {
        font-size: 16px;
        font-family: 'Roboto Medium';
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        white-space: nowrap;
        height: 30%;
    }

    .MenuItem-Tile__price-padding {
        padding: 5px 10px 2px 10px;
    }

    .Tile__from {
        font-size: 4vw;

        @media screen and (min-width: 600px) {
            font-size: 1rem;
        }

        margin-right: 5px;
    }

    .MenuItem-Tile__price {
        position: absolute;
        color: white;
        font-weight: bold;
        display: flex;
        flex-direction: column;

        .tile-kcal {
            font-size: 3vw;
            font-weight: normal;
            margin: 0 auto;

            @media screen and (min-width: 600px) {
                font-size: 1rem;
            }
        }

        .tile-price {
            line-height: 4.5vw;
            font-size: 5vw;

            @media screen and (min-width: 600px) {
                line-height: 2rem;
                font-size: 2rem;
            }
        }
    }
}

.MenuItem-Tile__container {
	min-height: 100%;
    position: relative;
    border-radius: 15px;
    background-color: white;
    box-shadow: 2px 2px 7px #b0b0b0;
    display: flex;
    flex-direction: column;

    .MenuItem-Tile__image_container {
        overflow: hidden;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
		height: 100%;

        ion-col{
            display: block;
            padding: 0;
        }
    }

    .MenuItemImage {
        object-fit: contain;
        width: 100%;
    }

    .MenuItemImageProductPromo {
        position: absolute;
        width: 35%;
        bottom: 5px;
        max-width: 100px;
        max-height: 100px;

        &:nth-of-type(2) {
            left: 10px;
        }

        &:nth-of-type(3) {
            right: 10px;
        }
    }

    .addButton {
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #262626;
        border-radius: 21px;
        height: 42px;
        width: 90%;
        margin: auto;
        font-size: 16px;
        line-height: 16px;
        background: #fff;
        position: absolute;
        bottom: 10px;
        margin: auto;
        width: 90%;
        margin-left: 10px;
        margin-right: 10px;
        display: none;

        &:active {
            transform: translate(2px,2px);
        }
    }




    .detail-container {
        color: #262626;

        .item-name {
            margin-top: 5px;
            margin-left: 15px;
            margin-right: 15px;
            font-family: 'Roboto Medium';
            font-size: 16px;
            line-height: 18px;
            font-weight: 500;
            flex: 1;
        }

		.detail-inner-container {
            display: flex;
            justify-content: space-between;
            margin: 5px 0 10px;
            align-items: self-end;

            .item-kcal {
                margin-left: 15px;
                opacity: 0.7;
                font-size: 14px;
                line-height: 14px;
            }

            .item-price {
                margin: 0 15px 0 auto;
                font-family: 'Roboto Medium';
                font-size: 20px;
                line-height: 19px;
                display: flex;
            }

            .item-from {
                text-align: right;
                font-size: 14px;
                line-height: 14px;
                margin: 0 10px 0 0;
                align-self: self-end;
            }
		}
    }

    .MenuItemCaption {
        font-size: 16px;
        font-family: 'Roboto Medium';
        height: 30%;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        white-space: nowrap;
    }

    .MenuItem-Tile__price-padding {
        padding: 5px 10px 2px 10px;
    }

    .Tile__from {
        font-size: 4vw;

        @media screen and (min-width: 600px) {
            font-size: 1rem;
        }

        margin-right: 5px;
    }

    .MenuItem-Tile__price {
        position: absolute;
        color: white;
        font-weight: bold;
        display: flex;
        flex-direction: column;

        .tile-kcal {
            font-size: 3vw;
            font-weight: normal;
            margin: 0 auto;

            @media screen and (min-width: 600px) {
                font-size: 1rem;
            }
        }

        .tile-price {
            line-height: 4.5vw;
            font-size: 5vw;

            @media screen and (min-width: 600px) {
                line-height: 2rem;
                font-size: 2rem;
            }
        }
    }

    &.withAddButton {
        .addButton {
            display: flex;
            margin-top: 48px;
        }

        height: 290px;


        .MenuItem-Tile__image_container {
            overflow: hidden;
            max-height: 55%;
        }

        .item-kcal, .item-price {
            bottom: 60px;
        }
    }
}







.miDebugLabel {
    position: absolute;
    bottom: 20px;
    left: 10px;
    background: #fff;
    padding: 4px;
    font-size: 9px;
}


.MenuItemRowOuter {

    &.OutOfStock {
        background-color: var(--ion-color-light);

        .MenuItemImageWrapper {
            opacity: 0.3;
        }

        .MenuItemTitleWrapper {
            opacity: 0.3;
        }

        .menuItemRow {
            .OutOfStockWarning {
                display: inline-block;
            }
        }
    }

    .MenuItemIcon {
        width: 30px;
        display: inline-block;
        margin: 0 5px 0 0;
        vertical-align: middle;
    }

    .menuItemRow {
        border-bottom: 1px solid var(--ion-color-midgrey);
        padding: 5px 0;
        margin: 5px 15px;
        position: relative;

        .MenuItemImageWrapper {
            padding-left: 0;
            height: calc(100vw / 6);
            max-height: 100px;
            background-position: center center;
            background-size: 60% auto;
            background-repeat: no-repeat;
        }

        ion-text {


            &.MenuItemTitle {
                font-size: 14px;
                margin: 0 0 5px 0;
                font-weight: 500;
            }

            &.MenuItemPrice {
                font-size: 14px;
                margin: 0 0 5px 0;
                font-weight: 500;
            }

            &.CatDescription {
                font-size: 12px;
                color: var(--ion-color-medium);
                margin: 0 0 5px 0;
            }
        }

        .MenuItemImage {
            border-radius: 10px;
            overflow: hidden;
        }

        &:last-child {
            border-bottom: none;
        }



        .OutOfStockWarning {
            color: var(--ion-color-dark);
            margin: 0 10px;
            font-weight: 600;
            display: none;
        }
    }
}
