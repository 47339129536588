.OrderConfirmationPaymentButtonLoading{
    opacity: .6;
    &:hover{
        cursor: default;
    }
}

.order-confirmation-outer {
    .order-confirmation {  
        background-color: #F8F6F2B2;
        height: 100vh;    
        .please-choose {
        font-family: 'Roboto Medium';
        font-size: 24px;
        line-height: 28px;
        height: 20%;
        .please-choose-caption {
            margin: 30px 10px 0px 10px;
        }
        }
        .order-total {
            height: 20%;
            background-color: white;
            border-top: 1px solid #979797;
            border-bottom: 1px solid #979797; 
            .order-total-caption { 
            text-align: center;
            font-size: 18px;
            line-height: 24px;
            font-weight: 400;
            margin-bottom: 10px;
            }
            .order-total-actual {
            text-align: center;
            font-size: 34px;
            line-height: 24px;
            font-family: 'Roboto Medium';
            }
            .error-display {
                position: absolute;
                margin-top: 10px;
                width: 100%;
                text-align: center;
            }
        }
        .payment-method { 
            height: calc(40% - 40px); 
            align-items: flex-end;
            .OrderConfirmationButtonWrapper {
                display: grid;
                grid-row-gap: 10px;
                margin: 20px 0;
            .OrderConfirmationProcessing {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
            }
            ion-button.OrderConfirmationPaymentButton {
                font-size: 20px;
                font-weight: 100;
                width: 100%;
                max-width: unset;
                height: 40px;
                --border-radius: 0px;
                &::part(native) {
                    --border-radius: 0px;
                    font-size: inherit;
                    border-radius: 4px;
                    font-weight: 100;
                    background: var(--ion-color-dark);
                }
            }
            }
        }
    }
}

// Redundant (?), remove soon

.OrderConfirmation {
    &::part(scroll){
        display: flex;
        flex-direction: column;
    }    
    //align-items: stretch;

    .OrderConfirmationTop {
        max-height:calc(100% - 245px);
        overflow-y:scroll;
        .OrderConfirmatioStoreInfo {
            padding: 20px 15px;

            h4 {
                color: var(--ion-color-primary);
                font-size: 30px;
                font-weight: 500;
            }
        }
    }

    .OrderConfirmationBottom {
        padding: 20px;
        border-top: 2px solid var(--ion-color-light);
        margin-top: auto;
        box-shadow: 0 -5px 10px var(--ion-color-light);
        

        .OrderConfirmationBottomInner {
            .OrderConfirmationBalanceLine {
                border-bottom: 2px solid var(--ion-color-light);
                padding: 10px 0;
                margin: 5px 0;
                font-weight: 600;

                .OrderConfirmationBalanceLineRight {
                    text-align: right;
                }

                &:last-child {
                    border-bottom: none;
                }
            }

            .lockedPaymentMessage{
                font-size: 1.5rem;
            }

            .OrderConfirmationButtonWrapper {
                display: grid;
                grid-row-gap: 10px;
                margin: 20px 0;

                .OrderConfirmationProcessing{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }

                ion-button.OrderConfirmationPaymentButton {
                    border-radius: 0px;
                    font-size: 20px;
                    font-weight: 100;
                    width: 100%;
                    max-width: unset;
                    height: 40px;
                    --border-radius: 0px;

                    &::part(native) {
                        --border-radius: 0px;
                        font-size: inherit;
                        border-radius: 0px;
                        font-weight: 100;
                        background: var(--ion-color-dark);
                    }
                }
            }
        }
    }
}
