ion-picker {
    .picker-wrapper.sc-ion-picker-ios {
        --height:350px;
        bottom: -115px;
    }
}

.stepup__frame{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
}

.form-control {
    margin-bottom: 20px;
    height: 55px;
    width: 100%;
    background-color: white;
    border-style: solid;
    border-color: rgba(38, 38, 38, 0.1532);
    border-width: 1px;
    border-radius: 8px;
    margin-top: 10px;
}


.error-border {
    border-color: #C80000 !important;
}


.card-payment-toast {
    text-align:center;
    --background: #262626;
    --color:white;
}


.CheckoutPayCard__wrapper { }

#securityCode-container, #number-container{
    height: 55px;
    padding: 0;
}

.Checkout__form{
    font-family: var(--ion-font-family, inherit);
    //color: var(--ion-color-dark);
    color: var(--ion-color-step-400, #999999);
    padding-right:15px;
    padding-left:15px;    
    .nameInput{
        ::-moz-placeholder {
            color: #999999;
            opacity: 1;
        }
        ::-ms-input-placeholder {
            color: #999999;
            opacity: 1;
        }
        ::-webkit-input-placeholder {
            color: #999999;
            opacity: 1;
        }
    }
    .required {
        margin-top:3px;
        margin-left:2px;
        vertical-align: super;
        color:red;
    }
    .field-error-message {
        color: #C80000;
        font-size: 12px;
        display: flex;
        svg {
            margin-right: 5px;
        }
        margin-top: -10px;
        margin-bottom: 5px;
    }

    .cvv-outer {
        padding-bottom: 7.25rem;
    }

    .pay-footer-container {
        bottom: 0px;
        left: 0px;
        width: 100%;
        background-color: #171717;
        position: fixed;
        height: 6.25rem;
        display: flex;
        justify-content: center;
        align-items: center;
        .pay-footer {
            width: 100%;
            margin-left: 15px;
            margin-right: 15px;
            ion-button {
                width: 100%;
                height: 50px;
                font-weight: 600;
                font-size: 16px;
                line-height: 16px;
            }
            .error-message {
                color: red;
            }
        }
    }

    ion-input, ion-datetime {
        z-index: unset;
        color: rgba(38, 38, 38, 1);
        background-color: #FFFFFF;
        margin-bottom: 20px;        
        border-style: solid;
        border-color: rgba(38, 38, 38, 0.1532);
        border-width: 1px;
        border-radius: 8px;
        height: 55px;
        margin-top: 10px;
        &::part(placeholder) {
            margin-top: 5px;
        }
        &::part(text) {
            margin-top: 5px;
        }
    }

    ion-label {
        color: #262626;
        font-size: 18px;
        font-family: 'Roboto Medium';
        opacity: .8;
    }

    input, iframe{
        padding-top: 0.8rem !important;
        padding-bottom: 0.8rem !important;
        padding-left: 1rem !important;
    }

    .fields__double-row{
        display: flex;
    }

    .border-right{
        border-right: 1px solid var(--ion-color-light-shade);
    }

    .padding{
        padding: 1rem;
    }


}