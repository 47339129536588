
.filterContainer {
    overflow: scroll;

    &::-webkit-scrollbar {
        display: none;
    }


    .filterSlider {
        white-space: nowrap;
        padding-left: 10px;
        padding-right: 10px;
        overflow-x: auto;

        .filterButton {
            height: 50px;
            align-items: center;
            display: inline-block;
            border: 1px solid black;
            background: #fff;
            font-size: 18px;
            border-radius: 25px;
            padding: 10px;
            padding-left: 20px;
            padding-right: 20px;
            font-weight: 600;
            text-align: center;
            margin: 5px;
            margin-top: 10px;
            cursor: pointer;

            &:active {
                transform: translate(2px,2px);
            }

            &.selectedFilter {
                background-color: #B42867;
                border: 1px solid #B42867;
                color: #fff;
            }
        }
    }
}
