.EnterEmailWrapper {
	background: var(--ion-page-background);
	padding: 20px 15px;
	height: 100%;
	overflow-y: auto;
	border-top: solid 0.5px var(--ion-color-medium);
	/*display: flex;
    flex-direction: column;*/
	.receiptTitle {
		font-weight: 600;
		color: var(--ion-color-dark);
	}

	.EnterEmailTitle {
		font-size: 1.5rem;
		margin: 1rem 0;
	}

	.ReceiptText {
		font-weight: 400;
		color: var(--ion-color-dark);
		font-size: 24px;
	}

	.EmailInputFormWrapper {
		padding-bottom: 5rem;

		.inputLabel span {
			color: var(--ion-color-danger);
			font-size: 0.75em;
			vertical-align: top;
		}
	}

	ion-input.emailInput {
		background-color: var(--ion-color-white);
		padding: 0 20px;
		--padding-start: 10px;
		--padding-end: 10px;
		color: var(--ion-color-dark);
		font-family: var(--ion-font-family);
		border: solid 0.5px var(--ion-color-medium);
		border-radius: 5px;

		input {
			padding: 1rem 0.75rem;
		}
	}

	.cardInputWrapper {
		padding: 0 15px 15px 15px;
		position: relative;
		margin: 0 0 20px 0;
		-webkit-transition: all 0.6s linear; /* Saf3.2+, Chrome */
		-moz-transition: all 0.6s linear; /* FF3.7+ */
		-o-transition: all 0.6s linear; /* Opera 10.5 */
		transition: all 0.6s linear;

		&:after {
			-webkit-transition: border-color 0.6s linear; /* Saf3.2+, Chrome */
			-moz-transition: border-color 0.6s linear; /* FF3.7+ */
			-o-transition: border-color 0.6s linear; /* Opera 10.5 */
			transition: border-color 0.6s linear;
		}

		&.isClosed {
			padding-left: 0;

			.cardInputToggle {
				padding-left: 0;
			}

			&:after {
				content: '';
				position: absolute;
				width: 100%;
				height: calc(100% - 20px);
				border: 1px solid rgba(123,36,73, 0);
				left: 0;
				top: 15px;
				z-index: 0;
			}
		}

		&.isOpen {
			&:after {
				content: '';
				position: absolute;
				width: 100%;
				height: calc(100% - 20px);
				border: 1px solid rgba(123,36,73, 1);
				left: 0;
				top: 15px;
				z-index: 0;
			}

			.cardInputToggle {
				text-decoration: none;

				.cardInputToggleClose {
					display: inline-block;
				}
			}

			.cardInputInner {
				display: flow-root;
			}
		}

		.cardInputToggle {
			cursor: pointer;
			padding: 5px 15px;
			background-color: var(--ion-color-lightgreen);
			z-index: 1;
			position: relative;
			display: inline-block;
			text-decoration: underline;
			-webkit-transition: all 0.6s linear; /* Saf3.2+, Chrome */
			-moz-transition: all 0.6s linear; /* FF3.7+ */
			-o-transition: all 0.6s linear; /* Opera 10.5 */
			transition: all 0.6s linear;

			.cardInputToggleClose {
				margin: 0 0 0 10px;
				color: var(--ion-color-white);
				position: relative;
				width: 20px;
				height: 20px;
				vertical-align: top;
				display: none;

				&:after {
					content: '';
					position: absolute;
					left: 3px;
					top: 3px;
					width: calc(100% - 6px);
					height: calc(100% - 6px);
					background-color: var(--ion-color-primary);
					border-radius: 50%;
					z-index: -1;
				}
			}
		}

		.cardInputInner {
			h1 {
				color: var(--ion-color-primary);
			}

			.emailInput {
				margin: 0 0 0 0;

				&.error {
					border: 1px solid var(--ion-color-danger);
					background-color: var(--ion-color-lightred);
					color: var(--ion-color-danger-shade);
				}
			}

			.cardInputWarning {
				color: var(--ion-color-danger);
				margin: 10px 0 0 0;
			}
		}
	}

	.fieldWrapper {
		margin-top: 10px;
		margin-bottom: 30px;

		ion-item {
			&::part(native) {
				padding-left: 0;
			}
		}
	}

	.EmailFooter {
		height: 6.25rem;
		background-color: var(--ion-color-dark);
		z-index: 1;
		padding: 20px 10px;
		margin: 0 -15px;
		bottom: 0;
		position: absolute;
		width: 100%;

		.EmailFooterButton {
			&::part(native) {
				padding: 15px;
			}
		}
	}
}

.SubmitButtonWrapper {
	text-align: right;


	.SubmitEmail {
		&::part(native) {
			padding: 18px 40px 14px 40px;
		}
	}
}
